import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import '@builder.io/widgets';

// Put your API key here
builder.init('03ed57a0336a4adf890716fd06b37113');

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  const [description, setDescription] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [ogImage, setOgImage] = useState<string>();
  const [ogImageWidth, setOgImageWidth] = useState<number>();
  const [ogImageHeight, setOgImageHeight] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateHelmet = useCallback((data: any) => {
    if (!data) {
      return;
    }

    if (data.title) {
      setTitle(data.title);
    }
    if (data.ogImage) {
      setOgImage(data.ogImage);
    }
    if (data.ogImagewidth) {
      setOgImageWidth(data.ogImagewidth);
    }
    if (data.ogImageheight) {
      setOgImageHeight(data.ogImageheight);
    }
    if (data.description) {
      setDescription(data.description);
    }
  }, []);

  const locale = useMemo(() => {
    const storedLocale = localStorage.getItem('locale');
    return storedLocale ? storedLocale : undefined;
  }, []);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('page', {
          url: window.location.pathname,
          userAttributes: {
            locale,
          },
          options: {
            locale,
          },
        })
        .promise();

      setContent(content);
      setNotFound(!content || content.data.equityPage);

      // if the page title is found,
      // set the document title
      updateHelmet(content?.data);
    }
    fetchContent();
  }, [locale, updateHelmet]);

  // If no page is found, return
  // a 404 page from your code.
  // The following hypothetical
  // <FourOhFour> is placeholder.
  if (notFound && !isPreviewingInBuilder) {
    return <div />;
  }

  // return the page when found
  return (
    <>
      <Helmet>
        {/* Render the Builder page */}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogImageWidth !== undefined && (
          <meta property="og:image:width" content={ogImageWidth.toString()} />
        )}
        {ogImageHeight !== undefined && (
          <meta property="og:image:height" content={ogImageHeight.toString()} />
        )}
      </Helmet>

      <BuilderComponent model="page" content={content!} locale={locale} />
    </>
  );
}
